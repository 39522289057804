import React from 'react';
import './contact.css';
import { Header, Footer, ContactUs, ContactHelmet } from '../../components';

const Contact = () => {
  return (
    <div className='contact'>
      <ContactHelmet/>
      <Header />
      <ContactUs />
      <Footer/>
    </div>
  )
}

export default Contact
