import React from 'react';
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import History from "../../components/history/History";
import Press from "../../components/press/Press";
import AboutHelmet from "../../components/seo/AboutHelmet";
import "./about.css";

const About = () => {
  return (
    <div className='about'>
      <AboutHelmet />
      <Header />
      <History />
      <Press />
      <Footer />
    </div>
  )
}

export default About
