import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav'
import ContactForm from '../contact-form/ContactForm';
import './getintouch.css'
import phone from '../../assets/social-icons/phone-dark.svg'
import mail from '../../assets/social-icons/mail-dark.svg'
import insta from '../../assets/social-icons/instagram-dark.svg'
import fb from '../../assets/social-icons/facebook-dark.svg'
import pinterest from "../../assets/social-icons/pinterest-dark.png";
import yt from '../../assets/social-icons/youtube-dark.svg'

const GetInTouch = () => {
    return (
        <>
            <Container className='get-in-touch p-5'>
                <Row>
                    <Col md={7} className='contact-section'>
                        <Stack gap={4} className='px-4'>
                            <h2>Get In Touch</h2>
                            <div className='px-2'>
                                <ContactForm />
                            </div>
                        </Stack>
                    </Col>
                    <Col md={5} className='connect-with-us'>
                        <h4 className='pb-2'>Connect With Us</h4>
                        <Stack gap={4} className='px-4 py-5'>
                            <Row >
                                <Nav.Link href='mailto:parts@oldhouseparts.com' className='px-3'>
                                    <Stack direction='horizontal' gap={4}>
                                        <Image className='social-img' src={mail} fluid></Image>
                                        <h5>parts@oldhouseparts.com</h5>
                                    </Stack>
                                </Nav.Link>
                            </Row>
                            <Row >
                                <Nav.Link href='tel: 207-985-1999' className='px-3'>
                                    <Stack direction='horizontal' gap={4}>
                                        <Image className='social-img' src={phone} fluid></Image>
                                        <h5>(207) 985-1999</h5>
                                    </Stack>
                                </Nav.Link>
                            </Row>
                            <Row >
                                <Nav.Link href='https://www.instagram.com/oldhousepartsco/?hl=en' className='px-3'>
                                    <Stack direction='horizontal' gap={4}>
                                        <Image className='social-img' src={insta} fluid></Image>
                                        <h5>oldhousepartsco</h5>
                                    </Stack>
                                </Nav.Link>
                            </Row>
                            <Row >
                                <Nav.Link href='https://www.facebook.com/oldhousepartsco/' className='px-3'>
                                    <Stack direction='horizontal' gap={4}>
                                        <Image className='social-img' src={fb} fluid></Image>
                                        <h5>oldhousepartsco</h5>
                                    </Stack>
                                </Nav.Link>
                            </Row>
                            <Row >
                                <Nav.Link href='https://www.youtube.com/channel/UCJ0NXly6M11FTWWmKQS3Piw' className='px-3'>
                                    <Stack direction='horizontal' gap={4}>
                                        <Image className='social-img' src={yt} fluid></Image>
                                        <h5>oldhousepartsco</h5>
                                    </Stack>
                                </Nav.Link>
                            </Row>
                            <Row >
                                    <Nav.Link href='https://www.pinterest.com/oldhousepartscompany/' className='px-3'>
                                        <Stack direction='horizontal' gap={4}>
                                            <Image className='social-img' src={pinterest} fluid></Image>
                                            <h5>oldhousepartscompany</h5>
                                        </Stack>
                                    </Nav.Link>
                                </Row>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default GetInTouch
