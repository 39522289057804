import React from 'react';
import { Helmet } from 'react-helmet-async';
import metaImage from "../../assets/gallery-img/store.png";

const HomeHelmet = () => { 
  const socialLinks = {
    facebook: 'https://www.facebook.com/oldhousepartsco/',
    instagram: 'https://www.instagram.com/oldhousepartsco/',
    youtube: 'https://www.youtube.com/channel/UCJ0NXly6M11FTWWmKQS3Piw',
  };

  return (
    <div>
      <Helmet>
        <title>Old House Parts - Home</title>
        <meta name="description" content="Embark on a journey to Old House Parts & Company, in the charming town of Kennebunk, Maine just a two hour drive from Boston. Our antique sanctuary resides within an 11,000 square foot 1872 freight warehouse, a testament to the rich history that awaits inside. " />
        <meta name="keywords" content="Old House Parts, Antique Shop, Kennebunk, Maine, Architectural salvage, Vintage Furniture, Collectibles" />

        <meta property="og:url" content="https://www.oldhouseparts.com" />
        <meta property="og:title" content="Old House Parts Co." />
        <meta property="og:description" content="Explore our collection of vintage furniture, art, and collectibles at Old House Parts Co. in Kennebunk, Maine." />
        <meta property="og:image" content={metaImage} />

        {/* Facebook */}
        <meta property="article:publisher" content={socialLinks.facebook} />
        {/* Instagram */}
        <meta property="instagram:site" content={socialLinks.instagram} />
        {/* YouTube */}
        <meta property="youtube:channel" content={socialLinks.youtube} />
      </Helmet>
    </div>
  )
}

export default HomeHelmet
