import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/esm/Image';
import phone from '../../assets/social-icons/phone-dark.svg'
import mail from '../../assets/social-icons/mail-dark.svg'
import insta from '../../assets/social-icons/instagram-dark.svg'
import fb from '../../assets/social-icons/facebook-dark.svg'
import './contactus.css'
import Map from "../map/Map";
import ContactForm from "../contact-form/ContactForm";
import pin from "../../assets/social-icons/pinterest-dark.png";
import yt from '../../assets/social-icons/youtube-dark.svg';

const ContactUs = () => {
    return (
        <div>
            <Container>
                <Stack className='contact-us desktop'>
                    <Row>
                        <Col className='pt-3 pe-5'>
                            <h1>Contact Us</h1>
                            <p className='contact-us-paragraph'>We value your connection with us. Whether you have inquiries,
                                want to discuss a project, or simply share your thoughts,
                                we're here to listen. Feel free to reach out through the
                                provided contact form, and we'll get back to you promptly.
                                For more immediate assistance, our phone line is open during
                                business hours. Your feedback and questions are important to us,
                                and we look forward to being in touch. </p>
                        </Col>
                        <Col className='map-col'>
                            <Map />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Stack gap={4} className=' py-5'>
                                <Row >
                                    <Nav.Link href='mailto:parts@oldhouseparts.com' className='px-3'>
                                        <Stack direction='horizontal' gap={4}>
                                            <Image className='social-img' src={mail} fluid></Image>
                                            <h5>parts@oldhouseparts.com</h5>
                                        </Stack>
                                    </Nav.Link>
                                </Row>
                                <Row >
                                    <Nav.Link  href='tel: 207-985-1999' className='px-3'>
                                        <Stack direction='horizontal' gap={4}>
                                            <Image className='social-img' src={phone} fluid></Image>
                                            <h5>(207) 985-1999</h5>
                                        </Stack>
                                    </Nav.Link>
                                </Row>
                                <Row >
                                    <Nav.Link href='https://www.instagram.com/oldhousepartsco/?hl=en' className='px-3'>
                                        <Stack direction='horizontal' gap={4}>
                                            <Image className='social-img' src={insta} fluid></Image>
                                            <h5>oldhousepartsco</h5>
                                        </Stack>
                                    </Nav.Link>
                                </Row>
                                <Row >
                                    <Nav.Link href='https://www.facebook.com/oldhousepartsco/' className='px-3'>
                                        <Stack direction='horizontal' gap={4}>
                                            <Image className='social-img' src={fb} fluid></Image>
                                            <h5>oldhousepartsco</h5>
                                        </Stack>
                                    </Nav.Link>
                                </Row>
                                <Row >
                                    <Nav.Link href='https://www.youtube.com/channel/UCJ0NXly6M11FTWWmKQS3Piw' className='px-3'>
                                        <Stack direction='horizontal' gap={4}>
                                            <Image className='social-img' src={yt} fluid></Image>
                                            <h5>oldhousepartsco</h5>
                                        </Stack>
                                    </Nav.Link>
                                </Row>
                                <Row >
                                    <Nav.Link href='https://www.pinterest.com/oldhousepartscompany/' className='px-3'>
                                        <Stack direction='horizontal' gap={4}>
                                            <Image className='social-img' src={pin} fluid></Image>
                                            <h5>oldhousepartscompany</h5>
                                        </Stack>
                                    </Nav.Link>
                                </Row>
                            </Stack>
                        </Col>
                        <Col>
                            <div className='hours-container'>
                                <Row className='text-center'>
                                    <h3>Store Hours</h3>
                                </Row>
                                <Row className='pt-3 text-center'>
                                    <p>Monday-Saturday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9am-5pm</p>
                                    <p>Sunday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11am-5pm</p>
                                </Row>
                            </div>

                        </Col>
                    </Row>
                    <Row className='contact-form-container p-5'>
                        <h2>Get In Touch</h2>
                        <div className='pt-4'>
                            <ContactForm />
                        </div>
                    </Row>
                </Stack>

                <Stack className='contact-us mobile'>
                        <Col className=' mx-4'>
                            <h2>Contact Us</h2>
                            <p className='contact-us-paragraph'>We value your connection with us. Whether you have inquiries,
                                want to discuss a project, or simply share your thoughts,
                                we're here to listen. Feel free to reach out through the
                                provided contact form, and we'll get back to you promptly.
                                For more immediate assistance, our phone line is open during
                                business hours. Your feedback and questions are important to us,
                                and we look forward to being in touch. </p>
                        </Col>
                        <Col className='map-col mt-5'>
                            <Map />
                        </Col>
                        <Col>
                            <Stack gap={1} className='pt-5 pb-3 justify-content-center' direction='horizontal'>
                                    <Nav.Link className='px-3'>
                                            <Image className='social-img' src={mail} fluid></Image>
                                    </Nav.Link>
                                    <Nav.Link className='px-3'>
                                            <Image className='social-img' src={phone} fluid></Image>
                                    </Nav.Link>
                                    <Nav.Link className='px-3'>
                                            <Image className='social-img' src={insta} fluid></Image>
                                    </Nav.Link>
                                    <Nav.Link className='px-3'>
                                            <Image className='social-img' src={fb} fluid></Image>
                                    </Nav.Link>
                                    <Nav.Link className='px-3'>
                                            <Image className='social-img' src={pin} fluid></Image>
                                    </Nav.Link>
                            </Stack>
                        </Col>
                    <Row className='contact-form-container p-5'>
                        <h2>Get In Touch</h2>
                        <div className='pt-4'>
                            <ContactForm />
                        </div>
                    </Row>
                </Stack>
            </Container>
        </div>
    )
};

export default ContactUs
