import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'; // Import Modal component
import './contactform.css';

const ContactForm = () => {
    const [validated, setValidated] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false); // State to control modal visibility

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || form.name.value !== '') {
            event.preventDefault();
            event.stopPropagation();
            console.log("Invalid");
        } else {
            event.preventDefault();
            const formData = new FormData(form);
            try {
                const response = await fetch('https://www.oldhouseparts.com/sendEmail.php', {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    console.log('Email sent successfully!');
                    setShowSuccessModal(true); // Show success modal
                } else {
                    console.error('Failed to send email.');
                }
            } catch (error) {
                console.error('Error sending email:', error);
            }
        }
        setValidated(true);
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false); // Hide success modal
    };

    return (
        <div className='contact-form'>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><h5>Name</h5></Form.Label>
                    <Form.Control type="text" name="ohp-name" autoComplete="on" placeholder="First & Last name" required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                    <Form.Label><h5>Email Address</h5></Form.Label>
                    <Form.Control type="email" name="email" autoComplete="on" placeholder="name@example.com" required />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label><h5>Message</h5></Form.Label>
                    <Form.Control as="textarea" name="message" autoComplete="off" rows={3} placeholder="Tell us how we can help" required />
                </Form.Group>

                {/* Honeypot field */}
                <Form.Group className="mb-3 not-today">
                    <Form.Label><h5>Name</h5></Form.Label>
                    <Form.Control type="text" name="name" autoComplete="off" />
                </Form.Group>

                <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Email Sent Successfully!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Your email has been sent successfully.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseSuccessModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Button className='submit-btn' type="submit"><h6>Submit</h6></Button>
            </Form>
        </div>
    );
};

export default ContactForm;
