import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/esm/Container';
import "./faq.css"

const FAQ = () => {
    return (
        <div className='faq'>
            <Container>
                <h1>Frequently Asked Questions</h1>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h5>How do I purchase an item?</h5></Accordion.Header>
                        <Accordion.Body>
                            <p>All of the items on our website are for sale, and we have much more here in the store.
                                Please click the "Inquire" button on the item, send us a message asking any questions you
                                might have and we will send you more photos and a detailed description. This is also a good
                                time for us to help you be as sure as possible that you will be getting exactly what you need.
                                When you're ready to purchase, please call us at <a href='tel: 207-985-1990'>&#40;207&#41; 985-1999</a> to make your payment over the phone.
                                Send us an email at <a href="mailto:parts@oldhouseparts.com">parts@oldhouseparts.com</a> if you don't find what you need on the website.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h5>What if I don't see what I'm searching for on your website?</h5></Accordion.Header>
                        <Accordion.Body>
                            <p>Send us an email telling us what you're looking for along with ideal dimensions, defining characteristics,
                                and photos if possible or relevant.  We have a lot more inventory than what you see on the website!</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h5>Do you ship?</h5></Accordion.Header>
                        <Accordion.Body>
                            <p>We do ship certain items, while others are available for local pickup only. Click on any item to find out if it
                                qualifies for shipping and inquire about a shipping quote. We do not ship large items, such as bathtubs,
                                doors with glass, or doors over 34" x 80".</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h5>Will you hold an item for me?</h5></Accordion.Header>
                        <Accordion.Body>
                            <p>We do not hold items unless they are purchased in advance. Items that have been purchased can be held
                                for pickups only and must be collected within 60 days. While we make every effort to ensure the safety
                                of your item(s) during this period, please be aware that we are frequently moving inventory around, and
                                we cannot guarantee their safety at all times.

                                It's important to note that items held for pickup are at the buyer's risk. The Old House Parts Company is 
                                not responsible for any damage or theft, and as a result, we will not offer a refund in the event of such incidents.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><h5>Can I return an item?</h5></Accordion.Header>
                        <Accordion.Body>
                            <p> All items are sold as is, no returns. </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><h5>Do you purchase items?</h5></Accordion.Header>
                        <Accordion.Body>
                            <p>Yes! Please email us clear, detailed, well lit photos of items you're interested in selling, along with a full disclosure
                                and close ups of damaged/flawed areas.  Please include where you are located and your asking price.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><h5>What should I bring when I visit Old House Parts?</h5></Accordion.Header>
                        <Accordion.Body>
                            <p>When visiting our antique store, we recommend bringing along dimensions for spaces you're 
                                furnishing or items you're looking for (windows, doors, etc.), photos of items you already own for matching purposes, inspiration photos of 
                                desired looks, and a keen eye for antiques you love.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </div>
    )
}

export default FAQ
