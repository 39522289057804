import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/esm/Image';
import "./footer.css";
import logo from "../../assets/logo.png";
import fb from "../../assets/social-icons/facebook.svg";
import insta from "../../assets/social-icons/instagram.svg";
import phone from "../../assets/social-icons/phone.svg";
import mail from "../../assets/social-icons/mail.svg";
import pin from "../../assets/social-icons/pin.svg";
import pinterest from "../../assets/social-icons/pinterest.png";
import yt from "../../assets/social-icons/youtube.svg";

const Footer = () => {
    const navigate = useNavigate(); 

    const handleNavItemClick = (path) => {
        navigate(path);
        window.scrollTo(0, 0); 
    };

    return (
        <div className='footer'>
            <Container>

                {/* Desktop Footer (good until screen width < 990px) */}

                <Row className='desktop-footer'>
                    <Col className='text-left'>
                        <Link to='/' onClick={() => handleNavItemClick('/')}><Image className='logo' src={logo} fluid></Image></Link>
                        <p className='copyright'>© Old House Parts Company LLC., Rights Reserved</p>
                    </Col>
                    <Col>
                        <Row className='text-center'>
                            <h3>Store Hours</h3>
                        </Row>
                        <Row className='pt-3 text-center'>
                            <p>Monday-Saturday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9am-5pm</p>
                            <p>Sunday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11am-5pm</p>
                        </Row>
                        <Row className='text-center pt-5'>
                            <Stack direction='horizontal' className='align-items-center justify-content-center'>
                                <Image className='pin px-3 pb-2' src={pin}></Image>
                                <Nav.Link href='https://www.google.com/maps/place/The+Old+House+Parts+Company/@43.3819186,-70.5320379,17z/data=!3m1!4b1!4m6!3m5!1s0x4cb2ac2cf1ff2bd1:0x6594bd6acd845899!8m2!3d43.3819186!4d-70.5294576!16s%2Fg%2F1tyywqd4?entry=ttu'><h5 className='address'>1 Trackside Drive, Kennebunk, Maine</h5></Nav.Link>
                            </Stack>
                        </Row>
                    </Col>
                    <Col>
                        <Stack className='text-end'>
                            <Link  to='/' onClick={() => handleNavItemClick('/')}><h5>Home</h5></Link>
                            <Link  to='/inventory' onClick={() => handleNavItemClick('/inventory')}><h5>Inventory</h5></Link>
                            <Link  to='/about' onClick={() => handleNavItemClick('/about')}><h5>About</h5></Link>
                            <Link  to='/faqs' onClick={() => handleNavItemClick('/faqs')}><h5>FAQs</h5></Link>
                            <Link  to='/contact' onClick={() => handleNavItemClick('/contact')}><h5>Contact</h5></Link>
                        </Stack>
                        <Stack direction='horizontal' className='justify-content-end pt-4'>
                            <Nav.Link href='tel: (207) 985-1999' className='ps-4'><Image className='socials' src={phone} fluid></Image></Nav.Link>
                            <Nav.Link href='mailto:parts@oldhouseparts.com' className='ps-4'><Image className='socials' src={mail} fluid></Image></Nav.Link>
                            <Nav.Link href='https://www.facebook.com/oldhousepartsco/' className='ps-4'><Image className='socials' src={fb} fluid></Image></Nav.Link>
                            <Nav.Link href='https://www.instagram.com/oldhousepartsco/?hl=en' className='ps-4'><Image className='socials' src={insta} fluid></Image></Nav.Link>
                            <Nav.Link href='https://www.youtube.com/channel/UCJ0NXly6M11FTWWmKQS3Piw' className='ps-4'><Image className='socials' src={yt} fluid></Image></Nav.Link>
                            <Nav.Link href='https://www.pinterest.com/oldhousepartscompany/' className='ps-4'><Image className='socials' src={pinterest} fluid></Image></Nav.Link>
                        </Stack>
                    </Col>
                </Row>

                {/* Mobile Footer */}

                <Stack className='mobile-footer text-center'>
                    <h3>Store Hours</h3>
                    <Row className='pt-2'>
                        <p>Monday-Saturday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9am-5pm</p>
                    </Row>
                    <Row>
                        <p>Sunday:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11am-5pm</p>
                    </Row>
                    <Row className='text-center pt-4'>
                        <Stack direction='horizontal' className='align-items-center justify-content-center'>
                            <Image className='pin px-3 pb-2' src={pin}></Image>
                            <Nav.Link href='https://www.google.com/maps/place/The+Old+House+Parts+Company/@43.3819186,-70.5320379,17z/data=!3m1!4b1!4m6!3m5!1s0x4cb2ac2cf1ff2bd1:0x6594bd6acd845899!8m2!3d43.3819186!4d-70.5294576!16s%2Fg%2F1tyywqd4?entry=ttu'><h5 className='address'>1 Trackside Drive, Kennebunk, Maine</h5></Nav.Link>
                        </Stack>

                    </Row>
                    <Stack className='text-center justify-content-center pt-3 ' gap={2} >
                    <Link  to='/' onClick={() => handleNavItemClick('/')}><h5>Home</h5></Link>
                            <Link  to='/inventory' onClick={() => handleNavItemClick('/inventory')}><h5>Inventory</h5></Link>
                            <Link  to='/about' onClick={() => handleNavItemClick('/about')}><h5>About</h5></Link>
                            <Link  to='/faqs' onClick={() => handleNavItemClick('/faqs')}><h5>FAQs</h5></Link>
                            <Link  to='/contact' onClick={() => handleNavItemClick('/contact')}><h5>Contact</h5></Link>
                    </Stack>
                    <Stack direction='horizontal' className='justify-content-center pt-4'>
                        <Nav.Link href='tel: (207) 985-1999' className='px-3'><Image className='socials' src={phone} fluid></Image></Nav.Link>
                        <Nav.Link href='mailto:parts@oldhouseparts.com' className='px-3'><Image className='socials' src={mail} fluid></Image></Nav.Link>
                        <Nav.Link href='https://www.facebook.com/oldhousepartsco/' className='px-3'><Image className='socials' src={fb} fluid></Image></Nav.Link>
                        <Nav.Link href='https://www.instagram.com/oldhousepartsco/?hl=en' className='px-3'><Image className='socials' src={insta} fluid></Image></Nav.Link>
                        <Nav.Link href='https://www.youtube.com/channel/UCJ0NXly6M11FTWWmKQS3Piw' className='px-3'><Image className='socials' src={yt} fluid></Image></Nav.Link>
                        <Nav.Link href='https://www.pinterest.com/oldhousepartsco/' className='ps-4'><Image className='socials' src={pinterest} fluid></Image></Nav.Link>
                    </Stack>
                    <Row className='justify-content-center pt-5'>
                        <Link to='/' onClick={() => handleNavItemClick('/')}><Image src={logo} fluid></Image></Link>
                    </Row>

                    <p className='copyright'>© The Old House Parts Company LLC., Rights Reserved</p>
                </Stack>



            </Container>
        </div>
    )
}

export default Footer